import React from "react"
import { graphql, withPrefix } from "gatsby"

import Layout from "components/Layout"
import Breadcrumbs from "components/Breadcrumbs"
import ContactForm from "components/ContactForm"
// import Popup from "components/Popup"

import { Apartments, Nav, CallToAction } from "page_components/investment"

import {
  Hero,
  Gallery,
  About,
  Location,
  BanerBackground,
  AboutSecond,
  AboutThird,
  BanerConvenients,
  // Popup,
} from "page_components/investment/ultradom"

const Investment = ({ data, location }) => {
  const investment = "Ultradom"
  const investmentRaw = "ultradom"
  const allApartments = data.allWpApartmentUltradom.nodes

  return (
    <Layout
      location={location}
      seo={{
        title: investment,
        image: withPrefix("/ultradom-og-image.jpg"),
      }}
      rmGlobalPopup
    >
      <Breadcrumbs
        title={investment}
        parent={{ label: "Радом", url: "/ua/kvartyry/radom" }}
        subParent={{ label: "Квартири", url: "/ua/kvartyry" }}
      />
      <Hero title={investment} />
      <Nav
        title={investment}
        investment={investment}
        investmentRaw={investmentRaw}
      />
      <Apartments
        apartmentList={allApartments}
        investment={investment}
        investmentRaw={investmentRaw}
      />
      <About title={investment} />
      <BanerBackground />
      <AboutSecond />
      <Location />
      <AboutThird />
      <BanerConvenients />
      <Gallery />
      <CallToAction />
      <ContactForm
        formType="sale"
        investment={investment}
        investmentRaw={investmentRaw}
      />
      {/* <Popup type="radom" /> */}
    </Layout>
  )
}

export const query = graphql`
  query {
    allWpApartmentUltradom(sort: { fields: title, order: ASC }) {
      nodes {
        id
        title
        uri
        slug
        acfApartment {
          investment
          d3EstateUrl
          type
          apartmentNumber
          investmentBuilding
          buildingNumber
          area
          floor
          location
          price
          priceForSquareMeter
          roomsnumber
          status
          visualization2d {
            sourceUrl
          }
          placesList {
            area
            name
          }
        }
      }
    }
  }
`

export default Investment
